/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import {useLocalStorage} from "../hooks/useLocalStorage";

function Seo({ description, meta, title, translations : {heroTitle} }) {
  const { site, langList : {langs} } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title                                                                   
            description
            author
            keywords
          }
        }
          langList {
              langs
          }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const [storedLang] = useLocalStorage()

  return (
    <Helmet
      htmlAttributes={{
       locale: storedLang,
        lang: storedLang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | IT ${heroTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `title`,
          content: title,
        },
        {
          property: `description`,
          content: metaDescription,
        },
        {
          property: `type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: `cloud engineering, mobile, apps, web, api, backend, frontend, go, python, javascript, typescript, react, gatsby, html, css, aws, gcp`,
        },
      ].concat(meta)}
    >
      {langs.map((lang) => {
        const hrefAddress = `https://enterosoft.com/${lang}`
        return <link rel="alternate" href={hrefAddress} hrefLang={lang} key={lang}/>
      })}
      <link rel="alternate" href={"https://enterosoft.com"}    hrefLang={'x-default'} key={'x-default'}/>
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
      />{" "}
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
      />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `pl`,
  description: "cloud engineering mobile apps web  api backend frontend",
  title: "Enterosoft",
  meta: {
    keywords: `cloud engineering, mobile, apps, web, api, backend, frontend, go, python, javascript, typescript, react, gatsby, html, css, aws, gcp`,
  },
}

Seo.propTypes = {
  lang: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.object,
  title: PropTypes.string.isRequired,
}

export default Seo