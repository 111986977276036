import React, { createContext, useState, useContext } from 'react';
import { useLocation } from '@reach/router';

const LocaleContext = createContext('');
const LocaleProvider = ({ children }) => {

  const { pathname } = useLocation();
  const urlLang = pathname.split('/')[1];

  const [locale, setLocale] = useState( urlLang);
  const changeLocale = lang => {
    if (lang) {
      setLocale(lang);
    }
  };

  return (
    <LocaleContext.Provider value={{ locale, changeLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

const useLocale = () => {
  const context = useContext(LocaleContext);
  if (!context) {throw new Error('useLocale must be used within an LocaleProvider');}
  return context;
};

export { LocaleProvider, useLocale };