import { useStaticQuery, graphql } from 'gatsby'
import { useLocale } from './useLocale'

const query = graphql`
  query useTranslations {
    allNode {
      nodes {
        name
        services
        company
        values
        process
        models
        technology
        career
        contact
        heroTitle
        heroText
        servicesText
        servicesCloud
        servicesCloudText
        servicesMobile
        servicesMobileText
        servicesWebsite
        servicesWebsiteText
        servicesECommerce
        servicesECommerceText
        companyTextFirst
        companyTextSecond
        valuesHeading
        valuesQuality
        valuesQualityText
        valuesCooperation
        valuesCooperationText
        valuesProfessionalism
        valuesProfessionalismText
        valuesTransparency
        valuesTransparencyText
        valuesExperience
        valuesExperienceText
        valuesConvenience
        valuesConvenienceText
        valuesWholeWorldIsOurs
        valuesWholeWorldIsOursText
        processStart
        processMapHeading1
        processMapText1
        processMapHeading2
        processMapText2
        processMapHeading3
        processMapText3
        processMapHeading4
        processMapText4
        processMapHeading5
        processMapText5
        processMapHeading6
        processMapText6
        processMapHeading7
        processMapText7
        processFinish
        modelFixedPrice
        modelFixedPriceText
        modelTimeMaterial
        modelTimeMaterialText
        modelMixed
        modelMixedText
        technologies
        contactUs
        links
        privacyPolicy
        socialMedia
        contactFormHeading
        firstName
        lastName
        phoneNumber
        wrongPhoneNumber
        emailAddress
        wrongEmailAddress
        contactMessage
        acceptPrivacyPolicy
        send
        messageSend
        messageNotSend
        requiredField
        maxLength32
        acceptTerms
        RODOText
        page404Heading1
        page404Heading2
        takeMeBack
        accept
        privacyAcceptSentence
        linkToPrivacy
        agreeToContact
        minimumYear
        englishKnowledge
        linkedIn
        underYear
        year
        twoYears
        treeYears
        fourYears
        fiveYearsAndMore
        sent
        pending
        contactFormTitle
        careerHeroTitlePartOne
        careerHeroTitlePartTwo
        careerHeroDescription
        lookingForWork
        weOfferWorkFor
        professionHeroDescription
        whoWeLooking
        weLookingForOurTeam
        welcomed
        howWeWork
        whatWeOffer
        backendStack
        frontendStack
        unityStack
        remoteWork
        fullOrPartTimeJob
        elasticWorkHours
        realImpactOnDecisions
        rate
        rateFrontend
        rateUnity
        rateBackend
        workOnNewTechnologies
        weCreateModernApplications
        notChosen
        none
        claudExperience
        basicApiKnowledge
        addCv
        commercialExperience
        requiredFields
      }
    }
  }
`

const useTranslation = () => {
  const { locale } = useLocale()
  const {
    allNode: { nodes },
  } = useStaticQuery(query)
  return nodes.filter(el => el.name === locale)[0]
}

export default useTranslation
