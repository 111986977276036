import { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"

export function useLocalStorage() {
  const {
    langList: { langs },
  } = useStaticQuery(graphql`
    {
      langList {
        langs
      }
    }
  `)

  const { pathname } = useLocation()
  const urlLang = pathname.split("/")[1]
  const [storedLang, setStoredLang] = useState(urlLang)

  const setLangToStorage = value => {
    try {
      const valueToStore = value instanceof Function ? value(storedLang) : value
      setStoredLang(valueToStore)
      if (typeof window !== "undefined") {
        window.localStorage.setItem("lang", valueToStore)
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  useEffect(() => {
    const initialLang = window.navigator.language.slice(0, 2)

    if (typeof window === "undefined") {
      return setLangToStorage("en")
    }
    try {
      const item = window.localStorage.getItem("lang")
      langs.map(lang => {
        if (lang === initialLang) {
          return setStoredLang(initialLang)
        }
      })
      return setStoredLang(item ? item : initialLang)
    } catch (error) {
      return setStoredLang(urlLang)
    }
  }, [])

  return [storedLang, setLangToStorage, langs]
}
